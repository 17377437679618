import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

declare var $:any;

@Component({
  selector: 'modulo-produto-destaque-one',
  templateUrl: './produto-destaque-one.component.html',
  styleUrls: ['./produto-destaque-one.component.scss']
})
export class ProdutoDestaqueOneComponent implements OnInit,AfterViewInit {

  @Input("data") data; 
  @Input("classCustom") classCustom = ""; 
  @Input("template") template = null;  
  public produtos             = [];
  public categorias           = [];
  public produtosArray        = new Array();
  @ViewChild("slider") slider:ElementRef;
  public tabSelect = 0;
  public showList  = true;
  public itemActive = null;
  public configuracao = null;
  public color = null;
  
  constructor(
    private dataService: DataService,
    private router: Router
  ){}
  
  /**
   * 
   * To products
   * 
   */
  toProducts(){

    this.router.navigateByUrl("/servicos");

  }
  /***
   * 
   * Select Tab
   * 
   */
  selectTab(tab){

    this.showList  = false;
    this.tabSelect = tab;

    setTimeout(() => {
      this.initSlider();
      this.showList = true;
    },50);
    
  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Set active
   * 
   */
  setActive(id){

    this.itemActive = id;

    setTimeout(() => {
      this.initSlider();
    },10);

  }
  /**
   * 
   * Set item active
   * 
   */
  setItemActive(){

    if(this.data.data.length){
      this.itemActive = this.data.data[0].id;
    }

  }
  /**
   * 
   * Color
   * 
   */
  setColor(){

    if(this.configuracao != null){

      this.color = this.configuracao.cor_2;

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setItemActive();
  } 
  /**
   * 
   * After View
   * 
   */
  ngAfterViewInit(): void {
    //this.initSlider();
    this.configuracao = this.dataService.getConfiguracao();
    this.setColor();
  }

}
