import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'modulo-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.scss']
})
export class ParceiroComponent implements OnInit {

  @Input("data") data:any            = {};
  @Input("carrinho") carrinho:any    = {};  
  @Input("template") template        = "one";
  @Input("classCustom") classCustom  = "";
  public loader                      = false;
  public dataItem:any                = {};
  
  constructor(
    private app: AppService,
    private api: ApiService
  ){}
  /**
   * 
   * Open
   * 
   */
  open(dataItem:any){

    if(dataItem.ativar_link_parceiro){
      alert(dataItem.link_parceiro); 
    }

  }
  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.loader = true;

      this.api.contato().send(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:
            
            this.app.info("Recebemos o seu contato. Em breve retornaremos.");
            this.initDataItem();  
            
          break;
          case 0:
            
            let errors = this.app.getErrors([response.data]);
            this.app.info(errors);

          break;
          default:
            this.app.info("Houve um erro interno. Tente mais tarde ou informe nossa equipe.");
          break;
        }

      },(response) => {

        this.loader           = false;
        let error = this.api.formatError(response);
        this.app.info(error.message); 
    

      });    

    }catch(e){

      this.loader = false;
      this.app.info("Houve um erro interno: "+e.message);

    }

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      apelido: "parceiro",
      tipo: 6 
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.initDataItem();
  }

}
