<div class="module">
      <div class="module-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h2 *ngIf="data.mostrar_titulo" class="title">{{data.titulo}}</h2>
                  <h3 *ngIf="data.mostrar_subtitulo" class="subtitle">{{data.subtitulo}}</h3>
            </div>
      </div>
      <div class="module-content">
            <div class="container">
                  <div class="products">
                        <div class="row">
                              <div class="col-md-4" *ngFor="let p of data.data">
                                    <div class="item">
                                          <produto-item [data]="p"></produto-item>
                                    </div> 
                              </div>
                        </div>
                  </div>
                  <div class="content-button">
                        <button type="button" (click)="toProducts()">
                              <div class="icon">
                                    <i class="fa-solid fa-star"></i>
                              </div>
                              <span>Ver todos os atrativos</span>
                        </button>
                  </div>
            </div>
      </div>  
</div>      