<section class="page-home">  
  <div class="content-banner">
    <div class="container">
      <modulo posicao="banner-slider" class="wow fadeIn"></modulo>
    </div>
  </div>
  <modulos-destaque></modulos-destaque>
  <modulo posicao="newsletter"></modulo> 
  <modulo posicao="trip-advisor"></modulo>   
  <modulo posicao="whatsapp" template="fixo"></modulo> 
</section>   

 



