import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParceiroComponent } from 'src/app/modulos/parceiro/parceiro.component';
import { PartialModule } from '../../partial/partial.module';


@NgModule({
  declarations: [
    ParceiroComponent
  ],
  exports: [
    ParceiroComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class ModuloParceiroModule{}
