<div class="modulo links-uteis">
      <div class="modulo-titulo">
            <h3 class="modulo-title">Links Úteis</h3>
      </div>
      <div class="moduo-content">
            <ul>
                  <li>
                        <a href="https://www.maragogialagoas.com/dicas-maragogi/" target="_blank">
                              Blog
                        </a>      
                  </li> 
                  <li>
                        <a routerLink="/politicas-de-privacidade" title="Políticas de Privacidade">
                              Políticas de Privacidade
                        </a>      
                  </li>  
                  <li>
                        <a routerLink="/politicas-de-cancelamento" title="Políticas de Cancelamento">
                              Políticas de Cancelamento
                        </a>      
                  </li>
                  <li>
                        <a routerLink="/politicas-de-cookies" title="Políticas de Cookies">
                              Políticas de Cookies 
                        </a>      
                  </li> 
                  <li>
                        <a routerLink="/contato" title="Contato">
                              Contato
                        </a>      
                  </li>      
            </ul>      
      </div>      
</div>