<div class="produto-search box-pesquisa">
      <div class="header">
            <div class="box-title">
                <div class="icon">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <span>Pesquisa rápida</span>
            </div>
            <div class="box-icon" (click)="_openPesquisa()">
                <i class="material-icons">menu</i>
            </div>    
      </div>
      <div class="content animated" #contentPesquisa>    
            <form class="form form-custom" (submit)="search()"> 
                <div class="form-group">
                    <label>Nome do serviço:</label> 
                    <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
                </div>
                <!--<div class="form-group box-list">
                  <span class="subtitle">Tipo de experiência:</span>
                  <div class="item" *ngFor="let c of classificacao">
                    <input-check (change)="_changeClassificacao($event,c)" [label]="c.titulo"></input-check>
                  </div>  
                </div>--> 
                <div class="form-group box-list" *ngIf="categoria == null">
                    <span class="subtitle">Categoria:</span>
                    <combo-data-select (changeValue)="_changeCategoria($event)" [(ngModel)]="dataItem.produto_categoria_id" [data]="comboCategoria"></combo-data-select>
                </div> 
                <div class="form-group content-button">
                    <button type="submit" class="btn-one btn-icon">
                        <i class="fa-solid fa-paper-plane-top"></i>
                        <span>Buscar</span>
                    </button>    
                </div>   
            </form>
      </div>
</div> 
<loader *ngIf="loader"></loader>     