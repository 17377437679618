<button class="button" [ngClass]="{'active': isFavorito}" (click)="favoritar()" *ngIf="template == 'default'">
      <span *ngIf="!isFavorito">salvar nos favoritos</span> 
      <span *ngIf="isFavorito">salvo nos favoritos</span> 
</button>
<button class="button" [ngClass]="{'active': isFavorito}" (click)="favoritar()" *ngIf="template == 'two'">
      <img *ngIf="!isFavorito" width="20px" height="20px" defaultImage='/images/pixel/bg.png' lazyLoad="/images/template/icons/coracao-cinza.svg" alt="coração" />
      <img *ngIf="isFavorito" width="20px" height="20px" defaultImage='/images/pixel/bg.png' lazyLoad="/images/template/icons/coracao-branco.svg" alt="coração" />
      <span *ngIf="!isFavorito">salvar</span> 
      <span *ngIf="isFavorito">salvo</span> 
</button>
<loader *ngIf="loader"></loader>
