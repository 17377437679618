import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'partial-page-title',
  templateUrl: './partial-page-title.component.html',
  styleUrls: ['./partial-page-title.component.scss']
})
export class PartialPageTitleComponent implements OnInit {

  @Input("title") title                     = null;
  @Input("titlePart") titlePart             = null;
  @Input("classCustomH1") classCustomH1:any = null;
  @Input("classCustom") classCustom         =  "";
  @Input("quebraLinha") quebraLinha         = false;   

  constructor(){}

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {}

}
