<div class="modulo modulo-parceiro">
  <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
    <div class="container">
      <h3>{{data.titulo}}</h3>  
      <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4> 
    </div>
  </div>
  <div class="modulo-content">  
    <div class="container">
      <div class="parceiros">
        <ul>
          <li *ngFor="let p of data.parceiros">
            <div class="item" (click)="open(p)">
              <figure>
                <img [src]="p.logo" [alt]="p.nome" />
              </figure>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>      
</div>
<loader *ngIf="loader"></loader>
