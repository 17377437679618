import { Component, OnInit, Input, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

declare var $:any;

@Component({
  selector: 'partial-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit{

  @Input("classCustom") classCustom = "desktop";
  @Input("template") template       = "default";
  @ViewChild("content") content:any; 
  @ViewChild("close") close:any; 
  @ViewChild("overlay") overlay:any;   
  public show              = false;
  public menuCategoria:any = [];
  public destinos:any = [];
  public _showMenu = false;
  

  constructor(
    private dataService: DataService
  ){ 

    this.menuCategoria = this.dataService.getCategoriasMenu(); 
    this.destinos = this.dataService.getDestinos();

  }
  /**
   * 
   * Open
   * 
   */
  _open(){

    if(this.show){

      this.content.nativeElement.classList.remove("slide-right");
      //this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      this.show = false;

    }else{

      this.content.nativeElement.classList.add("slide-right");

      setTimeout(() => {
        this.content.nativeElement.classList.add("show");
      },200);

      //this.content.nativeElement.classList.remove("slide-left"); 
      this.overlay.nativeElement.classList.add("show");
      this.close.nativeElement.classList.add("show");
      this.show = true;

    }

  }
  /**
   * 
   * 
   * 
   */
  onClickShowSubitens(){

    $(this.content.nativeElement).find(".has-subitens").find("a").first().click(function(e){

      alert("hola")

      let ul = $(this).parent().find("ul.sub-itens").first();  

      if(!$(ul).hasClass("show")){
        $(ul).show().addClass("show");
      }else{
        $(ul).hide().removeClass("show");
      }
        
      e.preventDefault(); 
      return false;

    });

  }
  /**
   * 
   * Show menu
   * 
   */
  showMenu(){

    if(this.classCustom == "mobile"){
      
      this._showMenu = this._showMenu ? false : true; 

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{

   

  }

}
