<header class="header template-default">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-5">
                    
                </div>
                <div class="col-md-9 col-center col-7">    
                    <div class="content-action">
                        <div class="item item-cart">  
                            <btn-cart></btn-cart>   
                        </div>
                        <div class="item item-user"> 
                            <btn-usuario-login [usuarioService]="usuarioService"></btn-usuario-login>
                        </div> 
                    </div>    
                </div>
            </div>    
        </div>     
    </div> 
    <div class="container-logo">
        <div class="container">
            <modulo posicao="logo"></modulo>
            <div class="linha"></div>
        </div>  
    </div>
    <div class="container-menu desktop">
        <div class="container">
            <partial-menu></partial-menu>
        </div>
    </div>
    <div class="container-menu mobile">
        <div class="container"> 
            <partial-menu classCustom="mobile"></partial-menu>
        </div>
    </div>
</header> 