<div class="menu-partial {{classCustom}} template-{{template}}">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <i class="fa-solid fa-xmark"></i>
    </div>    
    <div class="content slide-right" #content>  
        <ul>
            <li>
                <a routerLink="/" title="Home">
                    <span>Home</span>
                </a>    
            </li>
            <li class="divisor">
                <span></span>
            </li>
            <li>
                <a routerLink="/sobre" title="Sobre a Aextour">
                    <span>Sobre a Aextour</span>
                </a>    
            </li>
            <li class="divisor">
                <span></span>
            </li>
            <li class="has-subitens">
                <a routerLink="destinos" title="Os melhores destinos" (click)="showMenu()">
                    <span>Os melhores destinos</span>  
                    <i class="fa-solid fa-chevron-down"></i>
                </a>
                <ul class="sub-itens animated scale-up-ver-center" [ngClass]="{'show': _showMenu}">
                    <li *ngFor="let item of destinos;let i=index;">
                      <a routerLink="destinos/{{item.apelido}}" [title]="item.nome">
                        {{item.nome}}  
                      </a>  
                    </li>
                </ul> 
            </li>
            <li class="divisor">
                <span></span>
            </li>
            <li>
                <a href="https://portal.aextour.com.br/admin/login" title="Portal do Agente" target="_blank">
                    <span>Portal do Agente</span>
                </a>    
            </li>
            <li class="divisor">
                <span></span>
            </li>
            <li>
                <a routerLink="contato" title="Contato">
                    <span>Contato</span>
                </a>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   
