<div [ngSwitch]="template" itemscope itemtype="https://schema.org/Organization">
  <div class="modulo modulo-endereco" *ngSwitchCase="null">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <h3>{{data.titulo}}</h3>
      <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
    </div> 
    <div class="modulo-content"> 
      <p class="endereco" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">{{data.params.rua}}, nº {{data.params.numero}} | {{data.params.bairro}}</span><br>
        <span itemprop="addressLocality">{{data.params.cidade}} </span> <span itemprop="postalCode">Cep: {{data.params.cep}}</span>
      </p> 
      <p *ngIf="data.params.telefone !== ''">
        <span>Telefone: {{data.params.telefone}}</span>
      </p> 
      <p *ngIf="data.params.email !== ''">
        <span>E-mail: {{data.params.email}}</span>
      </p> 
      <p *ngIf="data.params.horario !== ''">
        <span>Horário: {{data.params.horario}}</span>
      </p> 
      <p *ngIf="data.params.horario_sabado !== ''">
        <span>{{data.params.horario_sabado}}</span>
      </p>  
    </div>  
  </div>
  <div class="modulo modulo-endereco-contato" *ngSwitchCase="'contato'">
    <article class="endereco" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
      <h4>
        <span itemprop="streetAddress">{{data.params.rua}}, nº {{data.params.numero}}, {{data.params.bairro}}</span> - <span itemprop="addressLocality">{{data.params.cidade}}</span>
      </h4>
      <h4>
        CEP: <span itemprop="postalCode">{{data.params.cep}}</span>
      </h4>
    </article>
  </div>
  <div class="modulo modulo-endereco-footer" *ngSwitchCase="'footer'">
    <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/location.svg" alt="Localização" width="40px" height="40px" />
    <span>{{data.params.rua}}, {{data.params.numero}},<br>{{data.params.bairro}}, {{data.params.cidade}} | CEP {{data.params.cep}}</span> 
  </div>
  <div class="modulo modulo-contato-home" *ngSwitchCase="'contato-home'">
    <h3 *ngIf="data.mostrar_titulo">
      <img src="/images/icons/sao-paulo-ecoturismo/location.svg" alt="Onde Estamos - São Paulo Ecoturismo" />
      <span>{{data.titulo}}</span>
    </h3>
    <article class="endereco" itemscope itemtype="http://schema.org/PostalAddress">
      <h4 itemprop="streetAddress">{{data.params.rua}} Número {{data.params.numero}}</h4>
      <h4>{{data.params.bairro}} - <span itemprop="addressLocality">{{data.params.cidade}}</span></h4>
      <h4 itemprop="postalCode">CEP: {{data.params.cep}}</h4>
    </article>
  </div>
  <div class="modulo default-list" *ngSwitchCase="'page-contato'" itemscope itemtype="http://schema.org/PostalAddress">
    <div class="icon">
      <i class="fa-solid fa-location-dot color-white"></i>
    </div> 
    <div class="content">
      <strong class="cidade">{{data.params.cidade}}</strong>
      <span itemprop="streetAddress">{{data.params.rua}}, nº {{data.params.numero}} | {{data.params.bairro}}</span>
    </div> 
  </div>
</div>
