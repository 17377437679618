import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'partial-links-uteis',
  templateUrl: './links-uteis.component.html',
  styleUrls: ['./links-uteis.component.scss']
})
export class LinksUteisComponent implements OnInit {

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
